import React from 'react';

import { container, circle, circled, slogan, marked } from './slogans.module.scss';
import PenCircle from '../../assets/images/svg/pen-circle-03.svg';

interface ISlogansProps {
    className?: '';
}

const Slogans: React.FC<ISlogansProps> = ({ className = '' }) => {
    return (
        <div className={`${container} ${className}`}>
            <p className={slogan}>Makeup</p>
            <p className={`${slogan} ${marked}`}>
                Hair{` `}
                <span className={circled}>
                    colour
                    <PenCircle className={circle} />
                </span>
                &care
            </p>
            <p className={slogan}>Face&body care</p>
        </div>
    );
};

export default Slogans;
