import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, title, list, item, slider, sliderItem } from './qualities-gallery.module.scss';
import ShopIcon from '../../assets/images/svg/shop.svg';
import BrandsIcon from '../../assets/images/svg/brands.svg';
import HandIcon from '../../assets/images/svg/hand.svg';
import AwardIcon from '../../assets/images/svg/award.svg';
import ShieldIcon from '../../assets/images/svg/shield.svg';
import PawIcon from '../../assets/images/svg/paw.svg';
import useMediaQuery from '../../hooks/use-media-query';

import Title from '../atoms/title';
import Slider from '../hoc/slider';
import GalleryCard, { IGalleryCardProps } from '../molecules/gallery-card';

interface IQualitiesGalleryProps {
    className?: string;
}

const QualitiesGallery: React.FC<IQualitiesGalleryProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const showSlider = useMediaQuery(900);

    const cards: IGalleryCardProps[] = [
        {
            Icon: ShopIcon,
            lead: t('qualities.card.one.lead'),
            content: t('qualities.card.one.content'),
        },
        {
            Icon: BrandsIcon,
            lead: t('qualities.card.two.lead'),
            content: t('qualities.card.two.content'),
        },
        {
            Icon: HandIcon,
            lead: t('qualities.card.three.lead'),
            content: t('qualities.card.three.content'),
        },
        {
            Icon: AwardIcon,
            lead: t('qualities.card.four.lead'),
            content: t('qualities.card.four.content'),
        },
        {
            Icon: ShieldIcon,
            lead: t('qualities.card.five.lead'),
            content: t('qualities.card.five.content'),
        },
        {
            Icon: PawIcon,
            lead: t('qualities.card.six.lead'),
            content: t('qualities.card.six.content'),
        },
    ];

    return (
        <div className={`${container} ${className}`}>
            <Title className={title}>{t('qualities.title')}</Title>
            {showSlider ? (
                <Slider className={slider} settings={sliderSettings} slidesCount={cards.length}>
                    {cards.map((card, index) => {
                        return (
                            <GalleryCard
                                key={`quality-item-${index}`}
                                Icon={card.Icon}
                                className={sliderItem}
                                lead={card.lead}
                                content={card.content}
                            />
                        );
                    })}
                </Slider>
            ) : (
                <div className={list}>
                    {cards.map((card, index) => {
                        return (
                            <GalleryCard
                                key={`quality-item-${index}`}
                                Icon={card.Icon}
                                className={item}
                                lead={card.lead}
                                content={card.content}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    pauseOnHover: false,
    speed: 500,
    infinite: true,
};

export default QualitiesGallery;
