import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    title,
    who,
    team,
    qualities,
    brands,
    brandsBannerBox,
    brandsGallery,
    philosophy,
    bottomBannerBox,
    textContent,
    sectionRatio,
    philosophyRatio,
    wideRatio,
} from './about-us.module.scss';
import { IBannersCollection } from '../models/banner.model';
import { ISitePageContext } from '../models/site-page.model';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';
import { getCollectionByMap } from '../utils/get-collection-by-map';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import QualitiesGallery from '../components/organisms/qualities-gallery';
import SectionRow from '../components/organisms/section-row';
import Title from '../components/atoms/title';
import Markdown from '../components/hoc/markdown';
import Banner from '../components/organisms/banner';
import Slogans from '../components/molecules/slogans';
import BrandsGallery from '../components/organisms/brands-gallery';

interface IAboutUsProps {
    readonly data: {
        allBannersCollection: { edges: { node: IBannersCollection }[] };
    };
    readonly pageContext: ISitePageContext;
}

const AboutUs: React.FC<IAboutUsProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { allBannersCollection } = data;

    const collections = allBannersCollection.edges.map(({ node }) => node);

    const bannerAboutUsToPlace = {
        mainBanner: 'about',
        whoBanner: 'aboutWho',
        teamBanner: 'aboutTeam',
        brandsBanner: 'aboutBrands',
        philosophyBanner: 'aboutPhilosophy',
        bottomBanner: 'aboutBottom',
    };

    const {
        mainBanner,
        whoBanner,
        teamBanner,
        brandsBanner,
        philosophyBanner,
        bottomBanner,
    } = getCollectionByMap(collections, bannerAboutUsToPlace);

    return (
        <>
            <SEO title={t('about.title')} />
            <MainLayout
                className={layout}
                bannersData={mainBanner && { banner: mainBanner, titleData: { Tag: 'h1' } }}
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(pageContext)],
                }}
            >
                <Title className={title} intro={t('about.title.intro')}>
                    Verona Products Professional
                    <br />- We Are #InspiredByBeauty
                </Title>
                <SectionRow
                    className={who}
                    title={t('about.who.title')}
                    titleData={{ Tag: 'h3' }}
                    narrowContent={
                        <Markdown className={textContent}>{t('about.who.content')}</Markdown>
                    }
                    wideContent={
                        whoBanner && (
                            <Banner banner={whoBanner} ratioClass={sectionRatio} onlyImage={true} />
                        )
                    }
                />
                <SectionRow
                    className={team}
                    isReversed={true}
                    title={t('about.team.title')}
                    titleData={{ Tag: 'h3' }}
                    narrowContent={
                        <Markdown className={textContent}>{t('about.team.content')}</Markdown>
                    }
                    wideContent={
                        teamBanner && (
                            <Banner
                                banner={teamBanner}
                                ratioClass={sectionRatio}
                                onlyImage={true}
                            />
                        )
                    }
                />
                <QualitiesGallery className={qualities} />
                <SectionRow
                    className={brands}
                    title={t('about.brands.title')}
                    mobileLayout={3}
                    narrowContent={
                        <Markdown className={textContent}>{t('about.brands.content')}</Markdown>
                    }
                    wideContent={<Slogans />}
                />
                {brandsBanner && (
                    <Banner
                        banner={brandsBanner}
                        className={brandsBannerBox}
                        ratioClass={wideRatio}
                        onlyImage={true}
                    />
                )}
                <BrandsGallery className={brandsGallery} />
                <SectionRow
                    className={philosophy}
                    isReversed={true}
                    mobileLayout={2}
                    title={t('about.philosophy.title')}
                    narrowContent={
                        <Markdown className={textContent}>{t('about.philosophy.content')}</Markdown>
                    }
                    wideContent={
                        philosophyBanner && (
                            <Banner
                                banner={philosophyBanner}
                                ratioClass={philosophyRatio}
                                onlyImage={true}
                            />
                        )
                    }
                />
                {bottomBanner && (
                    <Banner
                        banner={bottomBanner}
                        className={bottomBannerBox}
                        ratioClass={wideRatio}
                        onlyImage={true}
                    />
                )}
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $placesIds: [String!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allBannersCollection(filter: { placeId: { in: $placesIds } }) {
            edges {
                node {
                    ...bannersCollectionFields
                }
            }
        }
    }
`;

export default AboutUs;
