import { IBanner, IBannersCollection } from '../models/banner.model';
import { getCollectionByPlaceId } from './get-collection-by-place-id';

import { config } from '../config';

const { places } = config;

export const getCollectionByMap = <BannerMapKey extends string>(
    collections: IBannersCollection[],
    bannerMap: Record<BannerMapKey, string>
): Record<BannerMapKey, IBanner | undefined> => {
    return Object.entries(bannerMap).reduce<
        Record<string, IBanner | undefined>
    >((acc, [key, value]) => {
        acc[key] = getCollectionByPlaceId(
            collections,
            places[value as keyof typeof places]
        )?.banners[0];

        return acc;
    }, {});
};
