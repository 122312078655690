import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { list } from './brands-gallery.module.scss';

import GalleryCard, { IGalleryCardProps } from '../molecules/gallery-card';

interface IBrandsGalleryProps {
    className?: string;
}

const BrandsGallery: React.FC<IBrandsGalleryProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const {
        logoIngrid,
        logoVollare,
        logoSkinUp,
        logoVittorio,
        logoRevitanum,
        logoRevia,
    } = useStaticQuery(query);

    const cards: IGalleryCardProps[] = [
        {
            image: getImage(logoIngrid),
            alt: 'Ingrid Cosmetics',
            content: t('brands.card.ingrid'),
        },
        {
            image: getImage(logoVollare),
            alt: 'Vollare Cosmetics',
            content: t('brands.card.vollare'),
        },
        {
            image: getImage(logoSkinUp),
            alt: 'Skin Up by Verona',
            content: t('brands.card.skin'),
        },
        {
            image: getImage(logoVittorio),
            alt: 'Vittorio Bellucci',
            content: t('brands.card.vittorio'),
        },
        {
            image: getImage(logoRevitanum),
            alt: 'Revitanum',
            content: t('brands.card.revitanum'),
        },
        {
            image: getImage(logoRevia),
            alt: 'Revia',
            content: t('brands.card.revia'),
        },
    ];

    return (
        <div className={`${list} ${className}`}>
            {cards.map((card, index) => {
                return <GalleryCard key={`gallery-card-${index}`} {...card} />;
            })}
        </div>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "about-brands.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        imageMobile: file(relativePath: { eq: "about-brands-mobile.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        logoIngrid: file(relativePath: { eq: "logo-ingrid.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        logoVollare: file(relativePath: { eq: "logo-vollare.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        logoSkinUp: file(relativePath: { eq: "logo-skin-up.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        logoVittorio: file(relativePath: { eq: "logo-vittorio.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        logoRevitanum: file(relativePath: { eq: "logo-revitanum.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
        logoRevia: file(relativePath: { eq: "logo-revia.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
            }
        }
    }
`;

export default BrandsGallery;
