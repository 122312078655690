// extracted by mini-css-extract-plugin
export var bottomBannerBox = "about-us-module--bottom-banner-box--yMnWq";
export var brands = "about-us-module--brands--I1YqO";
export var brandsBannerBox = "about-us-module--brands-banner-box--eQrPg";
export var brandsGallery = "about-us-module--brands-gallery--UcIDn";
export var layout = "about-us-module--layout--QrSaS";
export var philosophy = "about-us-module--philosophy--tZaMD";
export var philosophyRatio = "about-us-module--philosophy-ratio--IPxaL";
export var qualities = "about-us-module--qualities--OI4F-";
export var sectionRatio = "about-us-module--section-ratio--hX91C";
export var team = "about-us-module--team--yLMjA";
export var textContent = "about-us-module--text-content--YSwqq";
export var title = "about-us-module--title--OHj9D";
export var who = "about-us-module--who--SZNJf";
export var wideRatio = "about-us-module--wide-ratio--rGAhW";