import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { card, iconBox, icon, imgWrapper, header, text } from './gallery-card.module.scss';

export interface IGalleryCardProps {
    className?: string;
    Icon?: React.SVGFactory;
    image?: IGatsbyImageData;
    alt?: string;
    lead?: string;
    content?: string;
}

const GalleryCard: React.FC<IGalleryCardProps> = ({
    className = '',
    Icon,
    image,
    alt = '',
    lead = '',
    content = '',
}) => {
    const ContentTag = lead ? 'p' : 'h3';

    return (
        <div className={`${card} ${className}`}>
            <div className={iconBox}>
                {Icon && <Icon className={icon} />}
                {image && (
                    <GatsbyImage
                        className={imgWrapper}
                        alt={alt || ''}
                        image={image}
                        objectFit="contain"
                    />
                )}
            </div>
            {lead && <h3 className={header}>{lead}</h3>}
            {content && <ContentTag className={text}>{content}</ContentTag>}
        </div>
    );
};

export default GalleryCard;
